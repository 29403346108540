/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-fill-down': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 9a3.5 3.5 0 110 7 3.5 3.5 0 010-7m.354 5.854l1.5-1.5a.5.5 0 00-.708-.708l-.646.647V10.5a.5.5 0 00-1 0v2.793l-.646-.647a.5.5 0 00-.708.708l1.5 1.5a.5.5 0 00.708 0M11 5a3 3 0 11-6 0 3 3 0 016 0"/><path pid="1" d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 018 12.5a4.5 4.5 0 011.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>',
    },
});
